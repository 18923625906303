<template>
  <div>
    <v-form>
      <p class="mb-2">
        Note that this is a multi-part question. When you correctly answer one part, the next part
        will become available. Consider the structure and curved arrows depicted below. What
        structure would result from the movement of electrons shown here.
      </p>

      <p class="mb-5 pl-12">
        <v-img src="/img/assignments/uciPL3_Q2a_main.png" style="max-width: 347px" />
      </p>

      <v-radio-group v-model="inputs.studentAnswerA" :disabled="!allowEditing" class="mb-0">
        <v-radio
          v-for="option in optionsA"
          :key="'pt-1-' + option.value"
          class="my-3"
          :value="option.value"
        >
          <template #label>
            <v-img :src="option.img" style="max-width: 97px" />
          </template>
        </v-radio>
      </v-radio-group>

      <div v-if="isPartACorrect" class="mt-5">
        <p class="mb-3">
          Now that you have correctly identified the first resonance structure, what other resonance
          structure would result from the movement of electrons depicted in the figure below?
        </p>

        <p class="mb-5 pl-12">
          <v-img src="/img/assignments/uciPL3_Q2b_main.png" style="max-width: 347px" />
        </p>

        <v-radio-group v-model="inputs.studentAnswerB" :disabled="!allowEditing" class="mb-0">
          <v-radio
            v-for="option in optionsB"
            :key="'pt-1-' + option.value"
            class="my-3"
            :value="option.value"
          >
            <template #label>
              <v-img :src="option.img" style="max-width: 97px" />
            </template>
          </v-radio>
        </v-radio-group>
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'ChemUCI51LCPL3_Q2',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswerA: null,
        studentAnswerB: null,
      },
      optionsA: [
        {
          img: '/img/assignments/uciPL3_Q2a_optionA.png',
          value: 'partAIncorrect1',
        },
        {
          img: '/img/assignments/uciPL3_Q2a_optionB.png',
          value: 'partAIncorrect2',
        },
        {
          img: '/img/assignments/uciPL3_Q2a_optionC.png',
          value: '1c',
        },
        {
          img: '/img/assignments/uciPL3_Q2a_optionD.png',
          value: 'partAIncorrect4',
        },
      ],
      optionsB: [
        {
          img: '/img/assignments/uciPL3_Q2b_optionA.png',
          value: 'partBIncorrect1',
        },
        {
          img: '/img/assignments/uciPL3_Q2b_optionB.png',
          value: 'partBIncorrect2',
        },
        {
          img: '/img/assignments/uciPL3_Q2b_optionC.png',
          value: '2c',
        },
        {
          img: '/img/assignments/uciPL3_Q2b_optionD.png',
          value: 'partBIncorrect4',
        },
      ],
    };
  },
  computed: {
    isPartACorrect() {
      return this.inputs.studentAnswerA && this.inputs.studentAnswerA === '1c';
    },
  },
};
</script>
